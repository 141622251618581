@import url('https://fonts.googleapis.com/css2?family=Poppins+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  height: 100%;
  font-family: "Poppins", sans-serif;
}
@layer base {
  body {
    @apply text-slate-700 h-full
  };
}
@layer utilities {
  #root {
    @apply flex flex-col min-h-full
  }
  .form-group {
    @apply relative
  }
  .form-label {
    @apply text-sm leading-6 font-medium text-slate-600 group-focus-within:text-orange-500 mb-1 inline-block;
  }
  .form-control {
    @apply focus:ring-2 focus:ring-orange-500 focus:outline-none appearance-none w-full leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 px-3 ring-1 ring-slate-200 read-only:bg-gray-100 read-only:opacity-50;
  }
  textarea.form-control {
    @apply w-full h-[100px] resize-y
  }
  .btn {
    @apply bg-orange-600 shadow text-white text-sm leading-6 font-medium py-2 px-3 rounded-lg disabled:opacity-40 text-center inline-block;
  }
  .btn-sm {
    @apply !py-1.5 !px-2 text-xs !rounded leading-4
  }
  .card {
    @apply p-4 border border-slate-100 rounded-lg shadow backdrop-blur bg-white/50
  }
  .text-danger {
    @apply text-red-600
  }
  .successMessageAlert {
    @apply px-3 py-2 border-slate-100 bg-green-600/60 text-white rounded-md
  }
  .errorMessageAlert {
    @apply px-3 py-2 border-slate-100 bg-red-600/60 text-white rounded-md
  }
  .infoMessageAlert {
    @apply px-3 py-2 border-slate-100 bg-blue-600/60 text-white rounded-md
  }
  .bg-shape1 {
    width: 384px;
    height: 384px;
    position: relative;
    -webkit-filter: blur(90px); /* Safari */
    filter: blur(90px);
    -webkit-animation: one 10s infinite;
    animation: one 10s infinite;
    border-radius: 100%;
  }
  .bg-shape2 {
    width: 288px;
    height: 288px;
    -webkit-filter: blur(90px); /* Safari */
    filter: blur(90px);
    -webkit-animation: two 10s infinite;
    animation: two 10s infinite;
    border-radius: 100%;
  }
}
.shimmer {
  width: 100%;
  height: 1rem;
  background: #e4e4e4;
}
.shimmer-title {
  height: 1rem;
  background:  -webkit-linear-gradient(
        to right,
        #e0e0e0 0%,
        #f0f0f0 20%,
        #e0e0e0 40%,
        #e0e0e0 100%
    );
  background: linear-gradient(
        to right,
        #e0e0e0 0%,
        #f0f0f0 20%,
        #e0e0e0 40%,
        #e0e0e0 100%
    );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}
.shimmer-text {
  height: 0.5rem;
  background:  -webkit-linear-gradient(
        to right,
        #e0e0e0 0%,
        #f0f0f0 20%,
        #e0e0e0 40%,
        #e0e0e0 100%
    );
  background: linear-gradient(
        to right,
        #e0e0e0 0%,
        #f0f0f0 20%,
        #e0e0e0 40%,
        #e0e0e0 100%
    );
    background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}
@keyframes shimmer {
  0% {
      background-position: -200% 0;
  }
  100% {
      background-position: 200% 0;
  }
}
@keyframes one {
  0%{left: 0;top:0;}
  25%{left: -100px;top:300px;}
  50%{left: 20px;top: 150px;}
  75%{left: 50px;top: 100px;}
  100%{left: 0;top: 0;}
}
@keyframes two {
  0%{left: 0;top:0;}
  25%{left: 50px;top:10px;}
  50%{left: 100px;top: 50px;}
  75%{left: 50px;top: 300px;}
  100%{left: 0;top: 0;}
}

.shape{
  width: 600px;
  height: 600px;
  background: -o-linear-gradient(26deg, #0c39ffa6 23%, #adf7c394 23%, #ff693c8f 80%);
  background: linear-gradient(64deg, #0c39ffa6 23%, #adf7c394 23%, #ff693c8f 80%);
  border-radius: 28% 72% 22% 78% / 39% 23% 77% 61%; 
  -webkit-transform: rotate(0deg); 
      -ms-transform: rotate(0deg); 
          transform: rotate(0deg); 
  will-change: border-radius, transform;
  -webkit-filter: blur(90px);
          filter: blur(90px);
  -webkit-animation: blob 10s linear infinite, spin 100s linear infinite;
          animation: blob 10s linear infinite, spin 100s linear infinite;
  margin-top: 100px;
}
@media only screen and(max-width:800px){
  .shape {
    width: 300px;
    height: 300px;
  }
}
@-webkit-keyframes blob {
  0%, 100% {
    border-radius: 28% 72% 22% 78% / 39% 23% 77% 61%; 
  }
  50% {
    border-radius: 72% 28% 50% 50% / 55% 26% 74% 45%;  
  }
}
@keyframes blob {
  0%, 100% {
    border-radius: 28% 72% 22% 78% / 39% 23% 77% 61%; 
  }
  50% {
    border-radius: 72% 28% 50% 50% / 55% 26% 74% 45%;  
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);  
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);  
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}